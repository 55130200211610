import styles from './App.module.scss';
import Logo from './images/homelogo.svg';
import Frame from './images/homeframe.svg';

function App() {
  return (
    <div className={styles.main}>
      <img alt="logo" src={Logo} className={styles.logo} />

      <div className={styles.text}>
        <div>Coming soon</div>
        <img alt="frame" src={Frame} className={styles.frame}/>
      </div>

    </div>
  );
}

export default App;
